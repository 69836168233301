<template>
    <div class="restpassword">
        <div class="merchatlogin-wrap">
            <div class="merchatlogin-wrap-top">
                <img src="@/assets/sj_bj.png" alt />
            </div>
            <el-form :model="postData" status-icon :rules="rules" ref="ruleForm"
                class="merchatlogin-wrap-login demo-ruleForm">
                <el-form-item prop="tel">
                    <el-input placeholder="请输入手机号" type="number" v-model="postData.tel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="captcha" class="yanzhen">
                    <el-input placeholder="短信验证码" v-model="postData.captcha"></el-input>
                    <el-button type="primary" :disabled="isDisabled" @click="sendCode">{{buttonText}}</el-button>
                </el-form-item>
                <p class="submit" @click="submitData">登录</p>
            </el-form>
            <!-- 登录方式 -->
            <login-way :loginFrom="2"></login-way>
        </div>
    </div>
</template>
<script>
    import {
        sendsmsLogin,
        vcodeLogin
    } from "@/http/api.js";
    //import { Message } from "element-ui";
    import LoginWay from "./LoginChildren/LoginWay";
    export default {
        components: {
            LoginWay
        },
        data() {
            // <!--验证手机号是否合法-->
            let checkTel = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入手机号码"));
                } else if (!this.checkMobile(value)) {
                    callback(new Error("手机号码不合法"));
                } else {
                    callback();
                }
            };
            //  <!--验证码是否为空-->
            let checkSmscode = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入手机验证码"));
                } else {
                    callback();
                }
            };
            return {
                postData: {
                    tel: "",
                    captcha: ""
                },
                rules: {
                    tel: [{
                        validator: checkTel,
                        trigger: "blur"
                    }],
                    captcha: [{
                        validator: checkSmscode,
                        trigger: "blur"
                    }]
                },
                buttonText: "发送验证码",
                isDisabled: false, // 是否禁止点击发送验证码按钮
                flag: true
            };
        },
        mounted() {},
        methods: {
            // <!--发送验证码-->
            sendCode() {
                this.$refs.ruleForm.validateField("tel", valid => {
                    if (valid === "") {
                        let time = 60;
                        this.buttonText = "已发送";
                        this.isDisabled = true;
                        if (this.flag) {
                            this.sendMsg();
                            this.flag = false;
                            let timer = setInterval(() => {
                                time--;
                                this.buttonText = time + " 秒";
                                if (time === 0) {
                                    clearInterval(timer);
                                    this.buttonText = "重新获取";
                                    this.isDisabled = false;
                                    this.flag = true;
                                }
                            }, 1000);
                        }
                    }
                });
            },
            async sendMsg() {
                const res = await sendsmsLogin({
                    data: {
                        user_phone: this.postData.tel
                    }
                });
            },
            submitData() {
                this.$refs["ruleForm"].validate(valid => {
                    if (valid) {
                        this.vcodeLogin();
                    }
                });
            },
            async vcodeLogin() {
                const res = await vcodeLogin({
                    data: {
                        user_phone: this.postData.tel,
                        captcha: this.postData.captcha
                    }
                });
                if (res.code == "200") {
                    this.$LStorage.setItem("shopUserInfo", res.data);
                    let that = this;
                    this.$toast.success({message:'登录成功', onClose:function(){
                        that.$router.replace({
                            path: "/merchantwork"
                        });
                    }}); 
                } else {
                    this.$toast(res.msgs);
                }
            },
            // 验证手机号
            checkMobile(str) {
                let re = /^1[3456789]\d{9}$/;
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .restpassword {
        width: 100%;
        min-height: 100vh;
        background: #f6f6f6;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .merchatlogin-wrap {
            img {
                width: 100%;
                height: 4.54rem;
            }
        }

        .merchatlogin-wrap-login {
            width: 5.6rem;
            margin: 0 auto;
            background: #fff;
            border-radius: 0.07rem;
            padding: 0.39rem 0.45rem 0.45rem 0.52rem;
            box-sizing: border-box;
            margin-top: 0.45rem;

            input {
                width: 4.55rem;
                height: 0.73rem;
                background: #f5f5f5;
                font-size: 0.27rem;
            }

            input:nth-child(1) {
                margin-bottom: 0rem;
            }

            input:nth-child(2) {
                margin-bottom: 0.27rem;
            }

            .yanzhen {
                display: flex;
                margin-top: 0.26rem;

                /deep/ .el-form-item__content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .el-input {
                        width: 2.7rem;
                        background: #f5f5f5;
                        margin-right: 0.15rem;

                    }

                    button {
                        width: 1.69rem;
                        height: 40px;
                        padding: 0;
                        background-color: #e14e2a;
                        border-color: #e14e2a;
                        font-size: 0.24rem;
                    }
                }
            }

            .submit {
                width: 4.55rem;
                height: 0.73rem;
                border-radius: 0.04rem;
                font-size: 0.26rem;
                color: #fff;
                background: #e14e2a;
                margin-top: 0.5rem;
                text-align: center;
                line-height: 0.73rem;
            }

            .password {
                font-size: 0.23rem;
                display: flex;
                justify-content: flex-end;
                color: #e14e2a;
                margin-top: 0.36rem;
            }
        }
    }
</style>

